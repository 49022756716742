
.phone-button {
  cursor: pointer;
  position: fixed;
  left: 55px;
  bottom: -3px;
  transform: translate(-50%, -50%);
  background: #18c139 url('../assets/img/icons/phone-button.png') center no-repeat;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  z-index: 1000;
}

.phone-button:before,
.phone-button:after {
  content: " ";
  display: block;
  position: absolute;
  border: 1px solid #18c139;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: animate 1.5s linear infinite;
  opacity: 0;
  backface-visibility: hidden;
}
.phone-button:after{
  animation-delay: .5s;
}
.phone-button i {
  color:#fff;
  transition:.3s;
  animation: 1200ms ease 0s normal none 1 running shake;
  animation-iteration-count: infinite;
}

@keyframes animate
{
  0%
  {
    transform: scale(0.5);
    opacity: 0;
  }
  50%
  {
    opacity: 1;
  }
  100%
  {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    -webkit-transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
    -ms-transform: rotateZ(15deg);
    -webkit-transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
    -ms-transform: rotateZ(-10deg);
    -webkit-transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
    -ms-transform: rotateZ(7.5deg);
    -webkit-transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
    -ms-transform: rotateZ(-6deg);
    -webkit-transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
    -ms-transform: rotateZ(5deg);
    -webkit-transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
    -ms-transform: rotateZ(-4.28571deg);
    -webkit-transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
    -ms-transform: rotateZ(3.75deg);
    -webkit-transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
    -ms-transform: rotateZ(-3.33333deg);
    -webkit-transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}
