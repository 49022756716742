
.leasing {

  b {
    color: red;
  }

  .partner {
    box-shadow: 0 4px 5px rgba(0,0,0,0.2);
  }

  .partner:hover {
    transform: scale(1.4);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    cursor: pointer;
  }

  @media (max-width: 1400px) {
    .partner:hover {
      transform: scale(1.2);
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      cursor: pointer;
    }
  }

  @media (max-width: 576px) {
    .partner:hover {
      transform: scale(1);
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      cursor: pointer;
    }
  }
}