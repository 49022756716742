
.nav-bar {
  position: sticky;
  background-color: white;
  border-bottom: 1px solid black;
  top: 0;
  z-index: 1040;

  img {
    width: 335px;
    height: 70px;
  }

  &__links {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
  }

  @media (max-width: 440px) {
    img {
      width: 60vw;
      height: 12vw;
      min-width: 168px;
      min-height: 35px;
    }
  }
}