
.catalog {

  .category {
    font-size: 20px;
    box-shadow: 0 4px 5px rgba(0,0,0,0.2);
    cursor: pointer;
  }

  .category:hover {
    background-color: #3e3e3f;
    color: white;
  }

  .category.active {
    background-color: #0d6efd;
    color: white;
  }

  .card {
    box-shadow: 0 4px 5px rgba(0,0,0,0.2);
    height: 320px;

    div {
      font-size: 18px;
    }

    &__img {
      transition: all 0.6s ease;
      transform: perspective(1000px);
    }
  }

  .card:hover {
    transform: perspective(1000px) scale(1) rotateY(-20deg);
    left: 10px;
  }

  .card:hover .card__img {
    transform: perspective(1000px) translateZ(5px) scale(1.05) translateX(-20px) translateY(-15px);
  }

  @media (max-width: 768px) {
    .card {
      box-shadow: 0 4px 5px rgba(0,0,0,0.2);
      height: 375px;
    }

    .card:hover {
      transform: perspective(1000px) scale(1) rotateY(-20deg);
      left: 10px;
    }
  }

  @media (max-width: 576px) {
    .card {
      box-shadow: 0 4px 5px rgba(0,0,0,0.2);
      height: auto;
    }

    .card:hover {
      transform: perspective(1000px) scale(1);
      left: 0px;
    }

    .card:hover .card__img {
      transform: perspective(1000px) translateZ(5px) scale(1) translateX(0px) translateY(0px);
    }
  }
}